var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('a-form-item',{staticClass:"col-md-12",attrs:{"label":"Kategori"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'name',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini tidak boleh kosong'
                            },
                            {
                              min: 3,
                              message: 'Nama kategori minimal 3 karakter!'
                            } ]
                        }
                      ]),expression:"[\n                        'name',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Field ini tidak boleh kosong'\n                            },\n                            {\n                              min: 3,\n                              message: 'Nama kategori minimal 3 karakter!'\n                            },\n                          ]\n                        }\n                      ]"}],attrs:{"placeholder":"Nama kategori"}})],1),_c('a-form-item',{staticClass:"col-md-12",attrs:{"label":"Deskripsi"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'description',
                        {
                          rules: [
                            {
                              min: 3,
                              message: 'Deskripsi minimal 3 karakter!'
                            } ]
                        }
                      ]),expression:"[\n                        'description',\n                        {\n                          rules: [\n                            {\n                              min: 3,\n                              message: 'Deskripsi minimal 3 karakter!'\n                            },\n                          ]\n                        }\n                      ]"}],attrs:{"placeholder":"Deskripsi"}})],1),_c('a-form-item',{staticClass:"col-md-6",attrs:{"label":"Upload Foto"}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"icon","listType":"picture-card","showUploadList":false,"beforeUpload":_vm.beforeUpload},on:{"change":_vm.onFileChange}},[(_vm.imageUrl)?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.imageUrl,"alt":"icon"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Pilih Foto")])],1)])],1)],1)]),_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"btn btn-primary btn-with-addon",attrs:{"type":"submit"}},[_c('span',{staticClass:"btn-addon"},[_c('i',{staticClass:"btn-addon-icon fe fe-send"})]),_vm._v(" Simpan ")])])])])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"air__utils__heading"},[_c('h5',[_c('span',{staticClass:"mr-3"},[_vm._v("Ubah Kategori Topik")])])])}]

export { render, staticRenderFns }